import {
  ApolloClient,
  ApolloError,
  ApolloQueryResult,
  NormalizedCacheObject,
  gql,
} from '@apollo/client';
import {
  getCaregiver as getCaregiverType,
  getCaregiverVariables,
} from '@/__generated__/getCaregiver';
import {
  getCaregiverId as getCaregiverIdType,
  getCaregiverIdVariables,
} from '@/__generated__/getCaregiverId';
import {
  getSEOSegmentationBreadcrumbs as getSEOSegmentationBreadcrumbsType,
  getSEOSegmentationBreadcrumbsVariables,
} from '@/__generated__/getSEOSegmentationBreadcrumbs';
import {
  ReviewsByRevieweeVariables,
  ReviewsByReviewee as ReviewsByRevieweeType,
} from '@/__generated__/ReviewsByReviewee';
import {
  RevieweeMetrics as RevieweeMetricsType,
  RevieweeMetricsVariables,
} from '@/__generated__/RevieweeMetrics';
import {
  seoSimilarCaregiverProfiles as getSimilarCaregiversType,
  getSimilarCaregiversVariables,
} from '@/__generated__/getSimilarCaregivers';
import {
  getProviderPublicId as getProviderPublicIdType,
  getProviderPublicIdVariables,
} from '@/__generated__/getProviderPublicId';
import { GET_SEGMENTATION_BREADCRUMBS } from '@/API/getSegmentation';
import { apolloClientQuery } from './utils';
import { PROVIDER_PROFILE_FIELDS } from './fragments/caregiverProfile';

export { GET_SEGMENTATION_BREADCRUMBS } from '@/API/getSegmentation';

export const GET_CAREGIVER_ID = gql`
  query getCaregiverId($seoSegmentId: ID!) {
    seoCaregiverIDsBySeoSegmentID(seoSegmentId: $seoSegmentId) {
      ... on SEOCaregiverIDsBySEOSegmentIDSuccess {
        __typename
        caregiverIDs {
          caregiverUUID
          serviceId
          serviceProfileId
        }
      }
      ... on SEOCaregiverIDsBySEOSegmentIDError {
        __typename
        errors {
          ... on DataNotAvailableError {
            message
          }
        }
      }
    }
  }
`;

export const GET_PROVIDER_PUBLIC_ID = gql`
  query getProviderPublicId($providerId: ID!) {
    provider(id: $providerId) {
      ... on ProviderLookupSuccess {
        provider {
          publicId
        }
      }
      ... on ProviderLookupError {
        message
      }
    }
  }
`;

export const GET_REVIEW_SHORT_LINK_CREATE = gql`
  mutation getReviewShortLinkCreate($input: ReviewShortLinkCreateInput!) {
    reviewShortLinkCreate(input: $input) {
      ... on ReviewShortLinkCreatePayload {
        shortUrl
      }
      ... on ReviewFailureResponse {
        message
      }
    }
  }
`;

export const GET_CAREGIVER = gql`
  ${PROVIDER_PROFILE_FIELDS}
  query getCaregiver(
    $getCaregiverId: ID!
    $serviceId: ServiceType
    $isChildCare: Boolean!
    $isPetCare: Boolean!
    $isSeniorCare: Boolean!
    $isTutoring: Boolean!
    $isHousekeeping: Boolean!
  ) {
    getCaregiver(id: $getCaregiverId, serviceId: $serviceId) {
      backgroundChecks {
        backgroundCheckName
        whenCompleted
      }
      badges
      educationDegrees {
        currentlyAttending
        degreeYear
        educationLevel
        schoolName
        educationDetailsText
      }
      hasCareCheck
      hiredByCounts {
        locality {
          hiredCount
        }
      }
      isVaccinated
      member {
        id
        firstName
        hiResImageURL
        displayName
        address {
          city
          state
          zip
        }
        languages
        isPremium
        subService
      }
      placeInfo {
        name
      }
      profiles {
        serviceIds
        ...ProfilesFields
        commonCaregiverProfile {
          merchandizedJobInterests {
            companionCare
            dateNight
            lightCleaning
            mealPrepLaundry
            mover
            personalAssistant
            petHelp
            shopping
            transportation
          }
        }
      }
      recurringAvailability {
        dayList {
          monday {
            blocks {
              end
              start
            }
          }
          tuesday {
            blocks {
              end
              start
            }
          }
          wednesday {
            blocks {
              end
              start
            }
          }
          thursday {
            blocks {
              end
              start
            }
          }
          friday {
            blocks {
              end
              start
            }
          }
          saturday {
            blocks {
              end
              start
            }
          }
          sunday {
            blocks {
              end
              start
            }
          }
        }
      }
    }
  }
`;

export const REVIEWEE_METRICS = gql`
  query RevieweeMetrics(
    $revieweeId: ID!
    $revieweeType: ReviewInfoEntityType!
    $careType: ReviewInfoCareType!
  ) {
    revieweeMetrics(revieweeId: $revieweeId, revieweeType: $revieweeType, careType: $careType) {
      ... on RevieweeMetricsPayload {
        __typename
        metrics {
          averageRatings {
            type
            value
          }
          totalReviews
        }
      }
      ... on ReviewFailureResponse {
        message
      }
    }
  }
`;

export const REVIEWS_BY_REVIEWEE = gql`
  query ReviewsByReviewee(
    $revieweeId: ID!
    $revieweeType: ReviewInfoEntityType!
    $careType: ReviewInfoCareType
    $pageSize: Int
    $pageToken: String
  ) {
    reviewsByReviewee(
      revieweeId: $revieweeId
      revieweeType: $revieweeType
      careType: $careType
      pageSize: $pageSize
      pageToken: $pageToken
    ) {
      ... on ReviewsByRevieweePayload {
        __typename
        nextPageToken
        reviews {
          careType
          createTime
          description {
            displayText
            originalText
          }
          id
          ratings {
            type
            value
          }
          reviewer {
            imageURL
            source
            type
            displayName
          }
          verifiedByCare
          status
        }
      }
      ... on ReviewFailureResponse {
        message
      }
    }
  }
`;

export const SIMILAR_CAREGIVERS = gql`
  query seoSimilarCaregiverProfiles($seoSegmentId: ID!) {
    seoSimilarCaregiverProfiles(seoSegmentId: $seoSegmentId) {
      ... on SEOSimilarCaregiverProfilesSuccess {
        similarCaregiverProfiles {
          caregiverList {
            seoProfileURL
            avgReviewRating
            member {
              displayName
              address {
                city
              }
              imageURL
            }
          }
        }
      }
      ... on SEOSimilarCaregiverProfilesError {
        error {
          __typename
          message
        }
      }
    }
  }
`;

/**
 * Apollo Client Queries
 */

type GetCaregiverIdQuery = (
  client: ApolloClient<NormalizedCacheObject>,
  variables: getCaregiverIdVariables
) => Promise<
  | {
      queryResponse: ApolloQueryResult<getCaregiverIdType>;
      error: null;
    }
  | {
      queryResponse: null;
      error: ApolloError;
    }
>;

export const getCaregiverId: GetCaregiverIdQuery = async (client, variables) => {
  return apolloClientQuery(client, GET_CAREGIVER_ID, variables);
};

type GetCaregiverQuery = (
  client: ApolloClient<NormalizedCacheObject>,
  variables: getCaregiverVariables
) => Promise<
  | {
      queryResponse: ApolloQueryResult<getCaregiverType>;
      error: null;
    }
  | {
      queryResponse: null;
      error: ApolloError;
    }
>;

export const getCaregiver: GetCaregiverQuery = async (client, variables) => {
  return apolloClientQuery(client, GET_CAREGIVER, variables);
};

type GetSEOSegmentationBreadcrumbsQuery = (
  client: ApolloClient<NormalizedCacheObject>,
  variables: getSEOSegmentationBreadcrumbsVariables
) => Promise<
  | {
      queryResponse: ApolloQueryResult<getSEOSegmentationBreadcrumbsType>;
      error: null;
    }
  | {
      queryResponse: null;
      error: ApolloError;
    }
>;

export const getSEOSegmentationBreadcrumbs: GetSEOSegmentationBreadcrumbsQuery = async (
  client,
  variables
) => {
  return apolloClientQuery(client, GET_SEGMENTATION_BREADCRUMBS, variables);
};

type GetRevieweeMetricsQuery = (
  client: ApolloClient<NormalizedCacheObject>,
  variables: RevieweeMetricsVariables
) => Promise<
  | {
      queryResponse: ApolloQueryResult<RevieweeMetricsType>;
      error: null;
    }
  | {
      queryResponse: null;
      error: ApolloError;
    }
>;

export const getRevieweeMetrics: GetRevieweeMetricsQuery = async (client, variables) => {
  return apolloClientQuery(client, REVIEWEE_METRICS, variables);
};

type GetReviewsByRevieweeQuery = (
  client: ApolloClient<NormalizedCacheObject>,
  variables: ReviewsByRevieweeVariables
) => Promise<
  | {
      queryResponse: ApolloQueryResult<ReviewsByRevieweeType>;
      error: null;
    }
  | {
      queryResponse: null;
      error: ApolloError;
    }
>;

export const getReviewsByReviewee: GetReviewsByRevieweeQuery = async (client, variables) => {
  return apolloClientQuery(client, REVIEWS_BY_REVIEWEE, variables);
};

type GetSimilarCaregiversQuery = (
  client: ApolloClient<NormalizedCacheObject>,
  variables: getSimilarCaregiversVariables
) => Promise<
  | {
      queryResponse: ApolloQueryResult<getSimilarCaregiversType>;
      error: null;
    }
  | {
      queryResponse: null;
      error: ApolloError;
    }
>;

export const getSimilarCaregivers: GetSimilarCaregiversQuery = async (client, variables) => {
  return apolloClientQuery(client, SIMILAR_CAREGIVERS, variables);
};

type GetProviderPublicIdQuery = (
  client: ApolloClient<NormalizedCacheObject>,
  variables: getProviderPublicIdVariables
) => Promise<
  | {
      queryResponse: ApolloQueryResult<getProviderPublicIdType>;
      error: null;
    }
  | {
      queryResponse: null;
      error: ApolloError;
    }
>;

export const getProviderPublicId: GetProviderPublicIdQuery = async (client, variables) => {
  return apolloClientQuery(client, GET_PROVIDER_PUBLIC_ID, variables);
};
