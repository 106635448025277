import { gql } from '@apollo/client';

const CHILD_CARE_PROFILE_FIELDS = gql`
  fragment ChildCareProfileFields on ChildCareCaregiverProfile {
    ageGroups
    availabilityFrequency
    bio {
      experienceSummary
      title
      aiAssistedBio
    }
    numberOfChildren
    otherQualities
    payRange {
      hourlyRateFrom {
        amount
        currencyCode
      }
      hourlyRateTo {
        amount
        currencyCode
      }
    }
    qualities {
      certifiedNursingAssistant
      certifiedRegisterNurse
      certifiedTeacher
      childDevelopmentAssociate
      comfortableWithPets
      cprTrained
      crn
      doesNotSmoke
      doula
      earlyChildDevelopmentCoursework
      earlyChildhoodEducation
      firstAidTraining
      nafccCertified
      ownTransportation
      specialNeedsCare
      trustlineCertifiedCalifornia
    }
    recurringRate {
      hourlyRateFrom {
        amount
      }
      hourlyRateTo {
        amount
      }
    }
    rates {
      hourlyRate {
        amount
        currencyCode
      }
      numberOfChildren
      isDefaulted
    }
    supportedServices {
      carpooling
      craftAssistance
      errands
      groceryShopping
      laundryAssistance
      lightHousekeeping
      mealPreparation
      swimmingSupervision
      travel
    }
    yearsOfExperience
  }
`;

const PET_CARE_PROFILE_FIELDS = gql`
  fragment PetCareProfileFields on PetCareCaregiverProfile {
    rates {
      activity
      activityRate {
        amount
        currencyCode
      }
      activityRateUnit
    }
    bio {
      experienceSummary
      title
    }
    id
    otherQualities
    payRange {
      hourlyRateFrom {
        amount
        currencyCode
      }
      hourlyRateTo {
        amount
        currencyCode
      }
    }
    petSpecies {
      caresForAmphibians
      caresForBirds
      caresForCats
      caresForDogs
      caresForExoticPets
      caresForFarmAnimals
      caresForFish
      caresForHorses
      caresForMammals
      caresForOtherPets
    }
    qualities {
      isBondedAndInsured
      isNappsCertified
      isPSAMemberAndIsInsured
      isRedCrossPetFirstAidCertified
      doesNotSmoke
      ownsTransportation
    }
    recurringRate {
      hourlyRateFrom {
        currencyCode
        amount
      }
      hourlyRateTo {
        currencyCode
        amount
      }
    }
    supportedServices {
      administersMedicine
      boardsOvernight
      doesDailyFeeding
      doesHouseSitting
      doesPetSitting
      doesPetWalking
      groomsAnimals
      retrievesMail
      trainsDogs
      transportsPets
      watersPlants
    }
    yearsOfExperience
  }
`;

const SENIOR_CARE_PROFILE_FIELDS = gql`
  fragment SeniorCareProfileFields on SeniorCareCaregiverProfile {
    availabilityFrequency
    bio {
      experienceSummary
      title
    }
    id
    otherQualities
    payRange {
      hourlyRateFrom {
        amount
        currencyCode
      }
      hourlyRateTo {
        amount
        currencyCode
      }
    }
    qualities {
      alzheimersOrDementiaExperience
      certifiedNursingAssistant
      comfortableWithPets
      cprTrained
      doesNotSmoke
      homeHealthAideExperience
      hospiceExperience
      licensedNurse
      medicalEquipmentExperience
      ownTransportation
      registeredNurse
      woundCare
    }
    recurringRate {
      hourlyRateFrom {
        currencyCode
        amount
      }
      hourlyRateTo {
        currencyCode
        amount
      }
    }
    supportedServices {
      visitingPhysician
      visitingNurse
      transportation
      specializedCare
      specialNeeds
      respiteCare
      personalCare
      mobilityAssistance
      medicalTransportation
      medicalManagement
      mealPreparation
      lightHousekeeping
      liveInHomeCare
      hospiceServices
      homeModification
      homeHealth
      helpStayingPhysicallyActive
      heavyLifting
      feeding
      errands
      dementia
      companionship
      bathing
    }
    yearsOfExperience
  }
`;

const HOUSEKEEPING_PROFILE_FIELDS = gql`
  fragment HousekeepingProfileFields on HouseKeepingCaregiverProfile {
    availabilityFrequency
    bio {
      experienceSummary
      title
    }
    distanceWillingToTravel {
      unit
      value
    }
    id
    otherQualities
    payRange {
      hourlyRateFrom {
        amount
        currencyCode
      }
      hourlyRateTo {
        amount
        currencyCode
      }
    }
    qualities {
      comfortableWithPets
      doesNotSmoke
      ownTransportation
      providesEquipment
      providesSupplies
    }
    recurringRate {
      hourlyRateFrom {
        amount
        currencyCode
      }
      hourlyRateTo {
        amount
        currencyCode
      }
    }
    schedule {
      endTime
      id
      ruleName
      rules
      startTime
    }
    supportedServices {
      atticCleaning
      basementCleaning
      bathroomCleaning
      cabinetCleaning
      carpetCleaning
      changingBedLinens
      dishes
      dusting
      furnitureTreatment
      generalRoomCleaning
      houseSitting
      kitchenCleaning
      laundry
      moveOutCleaning
      organization
      ovenCleaning
      packingUnpacking
      petWasteCleanup
      plantCare
      refrigeratorCleaning
      surfacePolishing
      vacuumingOrMopping
      wallWashing
      windowWashing
    }
    yearsOfExperience
  }
`;

const TUTORING_PROFILE_FIELDS = gql`
  fragment TutoringProfileFields on TutoringCaregiverProfile {
    availabilityFrequency
    bio {
      experienceSummary
      title
    }
    id
    otherGeneralSubjects
    otherQualities
    payRange {
      hourlyRateFrom {
        amount
        currencyCode
      }
      hourlyRateTo {
        amount
        currencyCode
      }
    }
    qualities {
      additionalDetails {
        doesNotSmoke
        isComfortableWithPets
        ownsTransportation
      }
      professionalSkills {
        americanTutoringAssociationCertified
        certifiedTeacher
      }
    }
    supportedServices {
      tutorsInCenter
      tutorsInStudentsHome
      tutorsInTeachersHome
      tutorsOnline
    }
    specificSubjects
    otherSpecificSubject
    yearsOfExperience
  }
`;

const PROVIDER_PROFILE_FIELDS = gql`
  ${PET_CARE_PROFILE_FIELDS}
  ${CHILD_CARE_PROFILE_FIELDS}
  ${SENIOR_CARE_PROFILE_FIELDS}
  ${TUTORING_PROFILE_FIELDS}
  ${HOUSEKEEPING_PROFILE_FIELDS}
  fragment ProfilesFields on CaregiverProfiles {
    childCareCaregiverProfile @include(if: $isChildCare) {
      ...ChildCareProfileFields @include(if: $isChildCare)
    }
    petCareCaregiverProfile @include(if: $isPetCare) {
      ...PetCareProfileFields @include(if: $isPetCare)
    }
    seniorCareCaregiverProfile @include(if: $isSeniorCare) {
      ...SeniorCareProfileFields @include(if: $isSeniorCare)
    }
    tutoringCaregiverProfile @include(if: $isTutoring) {
      ...TutoringProfileFields @include(if: $isTutoring)
    }
    houseKeepingCaregiverProfile @include(if: $isHousekeeping) {
      ...HousekeepingProfileFields @include(if: $isHousekeeping)
    }
  }
`;

export { PROVIDER_PROFILE_FIELDS };
