import { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';

import getCaregiverDetailData, {
  type CaregiverDetailPageProps,
} from '@/components/pages/segmentationDetails/caregiver/caregiverDetailPageProps';

const UnifiedCaregiverDetail = dynamic(() => {
  return import('./UnifiedCaregiverDetail');
});

const CaregiverDetail = dynamic(() => {
  return import('./CaregiverDetail');
});

const CaregiverDetailPage = (props: CaregiverDetailPageProps) => {
  const { propsType } = props;
  const showUnifiedCaregiverProfile = propsType === 'CaregiverDetailDataProps';

  if (showUnifiedCaregiverProfile) {
    return <UnifiedCaregiverDetail {...props} />;
  }

  return <CaregiverDetail {...props} />;
};

export default CaregiverDetailPage;

export const getServerSideProps: GetServerSideProps = async (context) => {
  return getCaregiverDetailData(context);
};
