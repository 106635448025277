import { gql } from '@apollo/client';
import { COMMON_CAREGIVER_PROFILE_FIELDS } from './fragments/common';

const GET_PROVIDER_DETAILS = gql`
  ${COMMON_CAREGIVER_PROFILE_FIELDS}
  query getProviderDetails(
    $seoSegmentId: ID!
    $isSeniorCare: Boolean!
    $isChildCare: Boolean!
    $isHousekeeping: Boolean!
    $isTutoring: Boolean!
    $isPetCare: Boolean!
    $isCareGigs: Boolean!
    $isSpecialNeeds: Boolean!
    $profileDetailsPage: Boolean!
  ) {
    getSEOSegmentationBreadcrumbs(seoSegmentId: $seoSegmentId) {
      breadcrumbs {
        content
        url
      }
    }
    getSEOSegmentationCaregiverDetails(seoSegmentId: $seoSegmentId) {
      caregiver {
        member {
          id
          displayName
          firstName
          hiResImageURL
          age
          gender
          languages
          address {
            city
            state
          }
          subService
        }
        yearsOfExperience
        hasCareCheck
        numberOfReviews
        avgReviewRating
        responseTime
        profiles {
          ...CommonCaregiverProfileFields
        }
        collegeDegrees {
          collegeName
          degree
          degreeYear
        }
        verifications
      }
      latestCaregiverReview {
        reviewDate
        reviewer {
          displayName
          imageURL
        }
        reviewDate
        rating
        comment
      }
      nearByCaregivers {
        profileURL
        avgReviewRating
        member {
          displayName
          address {
            city
          }
          imageURL
        }
      }
    }
  }
`;

export default GET_PROVIDER_DETAILS;
