import {
  ApolloClient,
  ApolloError,
  TypedDocumentNode,
  gql,
  NormalizedCacheObject,
  ApolloQueryResult,
} from '@apollo/client';
import {
  getSegmentationIntroVariables,
  getSegmentationIntro as getSegmentationIntroType,
} from '@/__generated__/getSegmentationIntro';
import {
  getSEOSegmentationStats as getSEOSegmentationStatsType,
  getSEOSegmentationStatsVariables,
} from '@/__generated__/getSEOSegmentationStats';
import {
  getSEOSegmentationLinkSections as getSEOSegmentationLinkSectionsType,
  getSEOSegmentationLinkSectionsVariables,
} from '@/__generated__/getSEOSegmentationLinkSections';
import {
  getSEOSegmentationHeroImage as getSEOSegmentationHeroImageType,
  getSEOSegmentationHeroImageVariables,
} from '@/__generated__/getSEOSegmentationHeroImage';
import {
  getSEOSegmentationFAQsVariables,
  getSEOSegmentationFAQs as getSEOSegmentationFAQsType,
} from '@/__generated__/getSEOSegmentationFAQs';
import {
  getSEOSegmentationBreadcrumbsVariables,
  getSEOSegmentationBreadcrumbs as getSEOSegmentationBreadcrumbsType,
} from '@/__generated__/getSEOSegmentationBreadcrumbs';
import {
  CAREGIVER_SEARCH_RESULT_FIELDS,
  PROVIDER_SEARCH_RESULT_FIELDS,
  JOB_SEARCH_RESULT_FIELDS,
  ARCHIVED_JOB_SEARCH_RESULT_FIELDS,
} from './fragments/common';
import {
  getSegmentationEssential as getSegmentationEssentialType,
  getSegmentationEssentialVariables,
} from '../__generated__/getSegmentationEssential';
import { apolloClientQuery } from './utils';

export const GET_SEGMENTATION_ESSENTIAL: TypedDocumentNode<
  getSegmentationEssentialType,
  getSegmentationEssentialVariables
> = gql`
  ${CAREGIVER_SEARCH_RESULT_FIELDS}
  ${PROVIDER_SEARCH_RESULT_FIELDS}
  ${JOB_SEARCH_RESULT_FIELDS}
  ${ARCHIVED_JOB_SEARCH_RESULT_FIELDS}
  query getSegmentationEssential(
    $seoSegmentId: ID!
    $pageNumber: Int
    $filters: SEOSegmentationFilterInput
    $ipAddress: String!
    $userGeoInfo: String!
    $botInfo: String!
    $userAgent: String!
    $numberOfReviewsPerProfile: Int
  ) {
    getSEOSegmentationSearchResults(
      filters: $filters
      pageNumber: $pageNumber
      testCell: null
      seoSegmentId: $seoSegmentId
      ipAddress: $ipAddress
      botInfo: $botInfo
      userGeoInfo: $userGeoInfo
      userAgent: $userAgent
      numberOfReviewsPerProfile: $numberOfReviewsPerProfile
    ) {
      ...CaregiverSearchResultFields
      ...ProviderSearchResultFields
      ...JobSearchResultFields
      ...ArchivedJobSearchResultFields
      tests {
        testName
        testVariant
      }
      filters {
        location {
          city
          state
          zipcode
        }
      }
    }

    getSEOSegmentationDetails(seoSegmentId: $seoSegmentId) {
      seoPageMetadata {
        title
        metaKeywords
        indexable
        metaDescriptions
        canonicalURL
        seoPageId
        hrefLangLinks {
          countryCode
          languageCode
          url
        }
      }
      headline
      subHeadline
      conversionHeader
      searchResultsHeader
      searchFilters
      searchingFor
      pageType
      crossSell {
        avgPayRates {
          title
          url
          avgPayRate {
            rate {
              amount
              currencyCode
            }
            rateDuration
          }
        }
      }
      city
      state
      subService
      segmentationSubService
      serviceId
      serviceIdForMember
      avgPayRate {
        rate {
          amount
          currencyCode
        }
        rateDuration
      }
      minPayRate {
        rate {
          amount
          currencyCode
        }
        rateDuration
      }
      maxPayRate {
        rate {
          amount
          currencyCode
        }
        rateDuration
      }
      segmentNamePlural
      segmentNamePluralLowerCase
      segmentNameSingular
      segmentNameSingularLowerCase
      avgReviewRating
      smbHeadline
    }
  }
`;

export const GET_SEGMENTATION_INTRO: TypedDocumentNode<
  getSegmentationIntroType,
  getSegmentationIntroVariables
> = gql`
  query getSegmentationIntro($seoSegmentId: ID!, $local: Boolean!) {
    getSEOSegmentationIntro(seoSegmentId: $seoSegmentId) @skip(if: $local) {
      title
      description
    }
  }
`;
export const GET_SEGMENTATION_STATS: TypedDocumentNode<
  getSEOSegmentationStatsType,
  getSEOSegmentationStatsVariables
> = gql`
  query getSEOSegmentationStats($seoSegmentId: ID!, $isJobSegment: Boolean!) {
    getSEOSegmentationStats(seoSegmentId: $seoSegmentId) @skip(if: $isJobSegment) {
      title
      totalProviders
      avgYearsOfExperience
      totalReviewCount
      avgReviewRating
      avgPayRate {
        amount
        currencyCode
      }
      jobCount
    }
  }
`;

export const GET_SEGMENTATION_FAQS: TypedDocumentNode<
  getSEOSegmentationFAQsType,
  getSEOSegmentationFAQsVariables
> = gql`
  query getSEOSegmentationFAQs($seoSegmentId: ID!) {
    getSEOSegmentationFAQs(seoSegmentId: $seoSegmentId) {
      title
      faqList {
        question
        answer
      }
    }
  }
`;

export const GET_SEGMENTATION_HERO_IMAGE: TypedDocumentNode<
  getSEOSegmentationHeroImageType,
  getSEOSegmentationHeroImageVariables
> = gql`
  query getSEOSegmentationHeroImage($seoSegmentId: ID!) {
    getSEOSegmentationHeroImage(seoSegmentId: $seoSegmentId) {
      largeImageURL
      smallImageURL
    }
  }
`;

export const GET_SEGMENTATION_LINK_SECTIONS: TypedDocumentNode<
  getSEOSegmentationLinkSectionsType,
  getSEOSegmentationLinkSectionsVariables
> = gql`
  query getSEOSegmentationLinkSections($seoSegmentId: ID!) {
    getSEOSegmentationLinkSections(seoSegmentId: $seoSegmentId) {
      id
      title
      linkList {
        content
        createDate
        description
        url
        imageDetails {
          imageHeight
          imageSize
          imageUrl
          imageWidth
        }
      }
    }
  }
`;

export const GET_SEGMENTATION_BREADCRUMBS: TypedDocumentNode<
  getSEOSegmentationBreadcrumbsType,
  getSEOSegmentationBreadcrumbsVariables
> = gql`
  query getSEOSegmentationBreadcrumbs($seoSegmentId: ID!) {
    getSEOSegmentationBreadcrumbs(seoSegmentId: $seoSegmentId) {
      breadcrumbs {
        content
        url
      }
    }
  }
`;

type GetSegmentationQuery = (
  client: ApolloClient<NormalizedCacheObject>,
  variables: getSegmentationEssentialVariables
) => Promise<
  | {
      queryResponse: ApolloQueryResult<getSegmentationEssentialType>;
      error: null;
    }
  | {
      queryResponse: null;
      error: ApolloError;
    }
>;

export const getSegmentation: GetSegmentationQuery = async (client, variables) => {
  return apolloClientQuery(client, GET_SEGMENTATION_ESSENTIAL, variables);
};

type GetSegmentationIntroQuery = (
  client: ApolloClient<NormalizedCacheObject>,
  variables: getSegmentationIntroVariables
) => Promise<
  | {
      queryResponse: ApolloQueryResult<getSegmentationIntroType>;
      error: null;
    }
  | {
      queryResponse: null;
      error: ApolloError;
    }
>;

export const getSegmentationIntro: GetSegmentationIntroQuery = async (client, variables) => {
  return apolloClientQuery(client, GET_SEGMENTATION_INTRO, variables);
};

type GetSegmentationStatsQuery = (
  client: ApolloClient<NormalizedCacheObject>,
  variables: getSEOSegmentationStatsVariables
) => Promise<
  | {
      queryResponse: ApolloQueryResult<getSEOSegmentationStatsType>;
      error: null;
    }
  | {
      queryResponse: null;
      error: ApolloError;
    }
>;

export const getSegmentationStats: GetSegmentationStatsQuery = async (client, variables) => {
  return apolloClientQuery(client, GET_SEGMENTATION_STATS, variables);
};

type GetSegmentationFAQsQuery = (
  client: ApolloClient<NormalizedCacheObject>,
  variables: getSEOSegmentationFAQsVariables
) => Promise<
  | {
      queryResponse: ApolloQueryResult<getSEOSegmentationFAQsType>;
      error: null;
    }
  | {
      queryResponse: null;
      error: ApolloError;
    }
>;

export const getSegmentationFAQs: GetSegmentationFAQsQuery = async (client, variables) => {
  return apolloClientQuery(client, GET_SEGMENTATION_FAQS, variables);
};

type GetSegmentationHeroImageQuery = (
  client: ApolloClient<NormalizedCacheObject>,
  variables: getSEOSegmentationHeroImageVariables
) => Promise<
  | {
      queryResponse: ApolloQueryResult<getSEOSegmentationHeroImageType>;
      error: null;
    }
  | {
      queryResponse: null;
      error: ApolloError;
    }
>;

export const getSegmentationHeroImage: GetSegmentationHeroImageQuery = async (
  client,
  variables
) => {
  return apolloClientQuery(client, GET_SEGMENTATION_HERO_IMAGE, variables);
};

type GetSegmentationLinkSectionsQuery = (
  client: ApolloClient<NormalizedCacheObject>,
  variables: getSEOSegmentationLinkSectionsVariables
) => Promise<
  | {
      queryResponse: ApolloQueryResult<getSEOSegmentationLinkSectionsType>;
      error: null;
    }
  | {
      queryResponse: null;
      error: ApolloError;
    }
>;

export const getSegmentationLinkSections: GetSegmentationLinkSectionsQuery = async (
  client,
  variables
) => {
  return apolloClientQuery(client, GET_SEGMENTATION_LINK_SECTIONS, variables);
};

type GetSegmentationBreadcrumbsQuery = (
  client: ApolloClient<NormalizedCacheObject>,
  variables: getSEOSegmentationBreadcrumbsVariables
) => Promise<
  | {
      queryResponse: ApolloQueryResult<getSEOSegmentationBreadcrumbsType>;
      error: null;
    }
  | {
      queryResponse: null;
      error: ApolloError;
    }
>;

export const getSegmentationBreadcrumbs: GetSegmentationBreadcrumbsQuery = async (
  client,
  variables
) => {
  return apolloClientQuery(client, GET_SEGMENTATION_BREADCRUMBS, variables);
};
